import Swal from 'sweetalert2';
import 'jquery-mask-plugin';
import 'select2';

/* Tiny MCE */
import tinymce from 'tinymce'
import 'tinymce/icons/default'
import 'tinymce/themes/silver'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/link'
import 'tinymce/plugins/autoresize'
import './resource/tinymce.pt_BR'

var debug = false;

/**
 * -----------------------------------------------------------------------------
 * Máscaras
 * -----------------------------------------------------------------------------
 */
function maskInput()
{
    var SPMaskBehavior = (val) => {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },

    spOptions = {
        onKeyPress: (val, e, field, options) => {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    },

    docMaskBehavior = (val) => {
        return val.replace(/\D/g, '').length === 14 ? '00.000.000/0000-00' : '000.000.000-009999';
    },

    docOptions = {
        onKeyPress: (val, e, field, options) => {
            field.mask(docMaskBehavior.apply({}, arguments), options);
        }
    },

    validateString = (string) => {
        return string.replace(/ /g, '-')
            .normalize('NFD').replace(/[\u0300-\u036f]/g, '')
            .replace(/[<|,|>|\.|\?|\/|:|;|"|'|{|\[|}|\]|\||\\|~|`|!|@|#|\$|%|\^|&|\*|\(|\)|_|\+|=]+/g, '')
            .replace(/\-\-+/g, '-')
            .replace(/(^-+|-+$)/, '')
            .toLowerCase()
    }

    $(document).find('.mask-phone').mask(SPMaskBehavior, spOptions);
    $(document).find('.mask-doc').mask(docMaskBehavior, docOptions);
    $(document).find('.mask-zip').mask('00000-000');
    $(document).find('.mask-date').mask('00/00/0000');
    $(document).find('.mask-datetime').mask('00/00/0000 00:00');
    $(document).find('.mask-cpf').mask('000.000.000-00');
    $(document).find('.mask-validity').mask('00/0099');
    $(document).find('.mask-cvv').mask('000');
    $(document).find('.mask-hour').mask('##h', { reverse: true });
    $(document).find('.mask-money').mask('#.##0,00', { reverse: true });
    $(document).find('.mask-percent').mask('##%', { reverse: true });
    $(document).find('.mask-sanitize').on('keyup', function()
    {
        const val = $(this).val();
        $('.mask-sanitize-target').val(validateString(val));
    });
    $(document).find('.mask-sanitize-target').on('keyup', function()
    {
        const val = $(this).val();
        $(this).val(validateString(val));
    });
    $(document).find('.mask-card-name').off().on('keyup', function()
    {
        const val = $(this).val();
        $(this).val(val.toUpperCase());
    });

    $(document).find('.mask-card').off().on('keyup', function()
    {
        var cardnumber = $(this).val();

        cardnumber = cardnumber.replace(/[^0-9]+/g, '');

        var cards = {
            visa: /^4[0-9]{12}(?:[0-9]{3})/,
            mastercard: /^5[1-5][0-9]{14}/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
            amex: /^3[47][0-9]{13}/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
            elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
            hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
            jcb: /^(?:2131|1800|35\d{3})\d{11}/,
            aura: /^(5078\d{2})(\d{2})(\d{11})$/
        };

        for(var flag in cards) {
            if(cards[flag].test(cardnumber)) {
                $(this).css('background-image', "url('/dist/site/images/card-"+ flag +".png')")
            }
        }
    });

    $(document).find('.mask-card').mask('0000 0000 0000 0000');
}

/**
 * -----------------------------------------------------------------------------
 * Alerta de retorno
 * -----------------------------------------------------------------------------
 *
 * @param {object} data
 */
export function sweetAlert(data)
{
    if(1 == 1
        && typeof data.success === 'undefined'
        && typeof data.title === 'undefined'
        && typeof data.message === 'undefined'
        && typeof data.err === 'undefined'
    ) {
        if(typeof data.redirect !== 'undefined') {
            window.location.href = data.redirect;
        } else {
            window.location.reload();
        }

        return;
    }

    var fire = {
        icon: (data.success) ? 'success' : 'error',
    }

    if(typeof data.title !== 'undefined') {
        fire.title = data.title;
    }

    if(typeof data.message !== 'undefined') {
        fire.html = data.message;
    }

    if(typeof data.err !== 'undefined') {
        var errors = '<ul>';
        data.err.forEach((item) => {
            errors+= '<li>'+ item +'</li>';
        });

        errors+= '</ul>';

        fire.html = errors;
    }

    Swal.fire(fire).then(() => {

        if(data.success) {
            (typeof data.redirect == 'undefined')
                ? window.location.reload()
                : window.location.href = data.redirect;
        }
    })
}

/**
 * -----------------------------------------------------------------------------
 * Galeria de mídia e upload de arquivos
 * -----------------------------------------------------------------------------
 */
function refreshGallery()
{
    /** Fazer upload do arquivo */
    $('[data-trigger="upload"]').off().on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        const btn = $(this);
        const target = btn.data('target');

        $(target).removeAttr('disabled').trigger('click');

        if(debug) console.log('Vamos fazer upload!');
    });

    /** Selecionar arquivo(s) */
    $('.form-upload').off().on('change', function (e) {
        const input = $(this);
        const target = input.parents('.form-control-upload').find('.uploaded');
        const files = e.target.files;
        const multiple = input.attr('multiple');
        const name = input.parents('.form-control-upload').attr('id');

        var data = [];

        if (!multiple) {
            target.html('');
            target.removeClass('active');
        }

        $.each(files, (i, file) => {
            data.push({
                file: {
                    type: file.type,
                    src: URL.createObjectURL(file),
                    alt: file.name,
                    name: file.name,
                },
                multi: !multiple,
                name: name
            });
        });

        $.ajax({
            url: '/rest/media/upload',
            method: 'put',
            data: JSON.stringify(data),

            success: (data) => {
                target.append(data).addClass('active').trigger('change');

                if(debug) console.log('Selecionou o(s) arquivo(s)!');
            }
        });

    });

    /** Abrir galeria */
    $('[data-trigger="galleryOpen"]').off().on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        const btn = $(this);
        const gallery = $('#gallery');
        const target = btn.parents('.form-control-upload').attr('id');
        const multi = btn.data('multiple');
        var content = gallery.find('.gallery-content');

        $.ajax({
            url: '/rest/media/gallery',
            method: 'get',

            success: (data) => {
                gallery.attr('data-multiple', multi);

                if(multi) content.append();
                else content.html(data);

                refreshGallery();
            }
        });

        gallery.addClass('active');
        gallery.find('[data-trigger="gallerySubmit"]').attr('data-target', '#'+ target);

        if(debug) console.log('Abriu a galeria!');
    });

    /** Fechar galeria */
    $('[data-trigger="galleryClose"]').off().on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        const gallery = $('#gallery');

        gallery.removeClass('active');
        gallery.find('.gallery-content').html('');

        if(debug) console.log('Fechou a galeria');
    });

    /** Selecionar item */
    $('[data-trigger="gallerySelect"]').off().on('click', function (e) {
        e.stopPropagation();
        e.preventDefault();

        const btn = $(this);
        const gallery = btn.parents('.gallery');
        const multiple = gallery.attr('data-multiple');
        var selected = (btn.attr('data-select') === 'true');

        if (multiple !== 'true') {
            gallery.find('[data-trigger="gallerySelect"]')
                .attr('data-select', false);
        }

        btn.attr('data-select', !selected);
        gallery.trigger('change');

        if(debug) console.log('Selecionou um item da galeria');
    });

    $('[data-trigger="gallerySubmit"]').off().on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        const btn = $(this);
        const ids = btn.attr('data-select').split(',');
        const target = btn.attr('data-target');

        $.ajax({
            method: 'put',
            url: '/rest/media/gallery-select',
            data: JSON.stringify({
                ids: ids,
                name: target.replace('#',''),
                multi: (ids.lenght > 1)
            }),

            success: (data) => {
                $(target+' .uploaded').html(data).trigger('change');
                $('[data-trigger="galleryClose"]').trigger('click');
            }
        });

    })

    /** Habilitar o botão enviar caso algum item esteja selecionado */
    $('#gallery').off().on('change', function (e) {
        console.log('Galeria mudou!');

        const gallery = $(this);
        const arr_select = gallery.find('[data-select="true"]');
        const btn = $('[data-trigger="gallerySubmit"]');

        if (arr_select.length) {

            var ids = [];
            arr_select.each((i, item) => {
                ids.push($(item).attr('data-id'));
            });

            btn.attr('data-select', ids.join(','));
            btn.removeAttr('disabled');
        } else {
            btn.attr('data-select', '');
            btn.attr('disabled', 'disabled');
        }
    });

    /** Remover item selecionado */
    $('.uploaded').off().on('change', function(e)
    {
        $(this).find('[data-trigger="removeUploadItem"]').off().on('click', function(e)
        {
            const btn = $(this);
            const upload = btn.parents('.uploaded');
            const figure = btn.parents('.figure');
            figure.remove();

            var arr_figure = upload.find('.figure');
            if(!arr_figure.length) upload.removeClass('active');

            if(debug) console.log('Arquivo excluído!');
        });

        if(debug) console.log('Listagem de arquivos atualizada');
    });
    $('.uploaded').trigger('change');

    if(debug) console.log('Atualizou a galeria');
}

export function refreshForm()
{
    maskInput();
    refreshGallery();

    /** Select2 */
    $('select').select2({
        theme: 'bootstrap-5'
    });

    /** Clonar item */
    $('[data-trigger="addText"]').off().on('click', function(e)
    {
        e.stopPropagation();
        e.preventDefault();

        var target = $(this).attr('data-target');
        var container = $(target).parents('.form-group-content');

        $(target).clone().appendTo(container);
        container.find('input').last().val('').addClass('form-control-copy');

        maskInput();
    });

    $('.form-control-copy').off().on('change', function()
    {
        if(!$(this).val()) {
            $(this).remove();
        }
    });

    /** Abas do checkout */
    $('.nav-checkout .tab-pane:not(.active)').find('input').each(function(e)
    {
        $(this).removeAttr('required');
    });

    /** Desativar campos fora dentro da aba desativada */
    $('.nav-checkout .nav-link').off().on('click', function(e)
    {
        var target = $(this).attr('data-bs-target');

        $('.nav-checkout input').removeAttr('required');
        $('.nav-checkout '+ target +' input').attr('required','required');
    });

    /** Métodos de pagamento */
     $('.form .accordion-button').on('click', function(e)
     {
        var btn = $(this);
        var accordion = btn.parents('.accordion');
        var items = accordion.find('.accordion-item');
        var target = btn.attr('data-bs-target');

        items.each((i, el) => {
            var element = $(el).find(target);

            if(element.length > 0) {

                $(el).find('input').removeAttr('disabled');
                $(el).find('select').removeAttr('disabled');

            } else {

                $(el).find('input').attr('disabled', 'disabled');
                $(el).find('select').attr('disabled', 'disabled');
            }
        });
    });

    /** Buscar CEP */
    function updateCep(cep, container)
    {
        var country = container.find('.address-country');

        $.getJSON('https://viacep.com.br/ws/'+ cep + '/json/', (data) => {

            if(data.erro) return;

            container.find('input[type="text"]:not(.address-zip)').val('...');

            container.find('.address-street').val(data.logradouro);
            container.find('.address-number').val('');
            container.find('.address-complement').val('');
            container.find('.address-neighborhood').val(data.bairro);
            container.find('.address-city').val(data.localidade);
            container.find('.address-state').val(data.uf);

            if(country && data) {

                country.val('Brasil');

            } else if(country) {

                country.val('');
            }
        });
    }

    $('.mask-cep').off().on('change', function(e)
    {
        const cep = $(this);
        var address = cep.parents('.address');

        updateCep(cep.val(), address);
    });

    /** TinyMCE */
    if($('.form-richtext').length) {
        tinymce.remove();
        tinymce.init({
            selector: '.form-richtext',
            menubar: false,
            plugins: [
                'autolink',
                'lists',
                'link',
                'autoresize',
            ],
            skin: false,
            content_css: '/dist/site/styles/tinymce/content.min.css',
            language: 'pt_BR',
            toolbar: 'h2 h3 | bold italic | bullist numlist | link',
        });
    }

    /** Enviar formulário */
    $('form').off().on('submit', function(e)
    {
        e.preventDefault();
        e.stopPropagation();

        const form = $(this);
        const url = form.attr('action');
        const post = new FormData(this);

        var segment = window.location.pathname;
        segment = segment.split('/');
        segment = segment[1];

        $.ajax({
            type: 'post',
            url: url,
            data: post,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,

            success: (data) => {

                if(typeof data.err !== 'undefined') {
                    data.message = '<ul class="text-start">';
                    data.err.forEach((item) => {
                        data.message+= '<li>'+ item +'</li>';
                    })
                    data.message+= '</ul>';

                    data.icon = 'error';
                    data.type = 'danger';

                } else {

                    data.icon = 'success';

                }

                sweetAlert(data);
            },
        });
    });

    if(debug) console.log('Atualizou o formulário!');
}
